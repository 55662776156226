<template>
  <v-app>
    <v-container class="grey lighten-5">
      <v-row class="ma-4 justify-start align-center">
        <v-radio-group
          v-model="lessonMedia"
          row
          :disabled="overlay"
        >
          <v-radio
            label="Bài giảng Video"
            :value="1"
          ></v-radio>
          <v-spacer></v-spacer>
          <v-radio
            label="Bài giảng Text và Ảnh"
            :value="2"
          ></v-radio>
        </v-radio-group>
      </v-row>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(handleUpload)" method="post" id="add-video-form">
          <!-- <div class="text-center">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </div> -->

          <!--          <v-row class="v-row">-->
          <!--            <v-col cols="6" sm="12" md="12">-->
          <!--              <h5>Nhập tiêu đề bài học</h5>-->
          <!--              <div>-->
          <!--                <ValidationProvider-->
          <!--                  name="index"-->
          <!--                  rules="required"-->
          <!--                  v-slot="{ errors }"-->
          <!--                >-->
          <!--                  <v-textarea-->
          <!--                    outlined-->
          <!--                    v-model="lesson.description"-->
          <!--                    value="Nhập miêu tả về bài học"-->
          <!--                  ></v-textarea>-->
          <!--                  <span class="validate-error text-danger text-danger">-->
          <!--                    {{ errors[0] }}-->
          <!--                  </span>-->
          <!--                </ValidationProvider>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row v-if='isLessonVideo' class="ma-0">
            <v-col cols="6" sm="6" md="6" class="justify-center text-center">
              <ValidationProvider
                  name="file"
                  rules="required"
                  v-slot="{ errors }"
              >
                <v-file-input
                    prepend-icon="mdi-video"
                    v-model="video"
                    label="Tải video bài giảng"
                    @change="onChangeFileUpload"
                    accept=".mp4"
                    :disabled="overlay"
                ></v-file-input>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="uploading" cols="5" sm="5" md="5" >
              <v-row class="ma-0 justify-center text-center align-center">
                <span class="pa-2">Uploading ...</span>
              </v-row>
              <v-row class="ma-0 justify-center text-center align-center">
                <v-progress-linear
                  v-model="percentUpload"
                  height="25"
                >
                  <strong>{{ Math.ceil(percentUpload) }}%</strong>
                </v-progress-linear>
              </v-row>
            </v-col>
            <v-col v-if="!uploading && videoStatus" cols="5" sm="5" md="5" >
              <v-row class="ma-0 justify-end align-center">
                <span class="text-h6 pa-4" :class="classTextStatus">{{ videoStatus }}</span>
              </v-row>
              <v-row class="ma-0 justify-end align-center">
                <v-col cols="4" class="pa-0"></v-col>
                <v-col cols="4" class="pa-0 text-right"><span>Thời gian bắt đầu: </span></v-col>
                <v-col cols="4" class="pa-0 text-right"><span class="font-weight-bold font-italic">{{ getStartTimeEncrypt() }}</span></v-col>
              </v-row>
              <v-row class="ma-0 justify-end align-center">
                <v-col cols="4" class="pa-0"></v-col>
                <v-col cols="4" class="pa-0 text-right"><span>Thời gian kết thúc: </span></v-col>
                <v-col cols="4" class="pa-0 text-right"><span class="font-weight-bold font-italic">{{ getEndTimeEncrypt() }}</span></v-col>
              </v-row>
            </v-col>
             <!-- <v-col v-if="overlay" cols="1" sm="1" md="1" class="justify-center text-center">
              <span><strong>{{ Math.ceil(timeUpload / 1000) }}s</strong></span>
            </v-col> -->
          </v-row>
          <v-row class="ma-0"  v-if="isLessonVideo && 
            lesson.medias && lesson.medias.length > 0 && lesson.medias[0].src">
            <v-col cols="12" sm="12" md="12" class="">
              <div id="preview" class="justify-start">
                <video width="400px" controls id="video">
                  <source :src="lesson.medias[0].src" type="video/mp4"/>
                  Your browser does not support HTML video.
                </video>
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="12">
              <h5>Nhập nội dung bài học</h5>
              <div>
                <CkContent
                    name="lesson_content"
                    :content="lesson.content"
                    @getData="getContent"
                ></CkContent>
              </div>
            </v-col>
          </v-row>
          <v-row class="text-center justify-center">
            <div class="my-4">
              <v-btn small class="ma-2 white--text" color="blue-grey" @click="comeBack"
              >Hủy
              </v-btn
              >
              <v-btn form="add-video-form" small color="primary" type="submit" :disabled="overlay"
              >Lưu
              </v-btn
              >
            </div>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import CkContent from "@/components/ck-content";
import {
  GET_LESSON_DETAIL,
  UPDATE_LESSON,
  UPLOAD_LESSON_VIDEO,
} from "@/store/lessons.module";
import { GET_COURSE_DETAIL } from "@/store/courses.module";
import { ENCRYPT_MEDIA_PROCESS, LESSON_MEDIA_TYPE } from "@/common/constants";

export default {
  components: {
    CkContent,
  },
  data: function () {
    return {
      overlay: false,
      course_id: null,
      video: {},
      url: "",
      videoUrl: "",
      uploading: false,
      ENCRYPT_MEDIA_PROCESS: ENCRYPT_MEDIA_PROCESS,
      LESSON_MEDIA_TYPE: LESSON_MEDIA_TYPE,
      videoStatus: '',
      classTextStatus: 'blue--text',
      lessonMedia: 1
    };
  },
  computed: {
    ...mapGetters({
      lesson: "lessonDetail",
      course: "detailCourse",
      percentUpload: 'percentUpload',
      timeUpload: 'timeUpload',
    }),
    isLessonVideo() {
      return this.lessonMedia == LESSON_MEDIA_TYPE.VIDEO;
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Bài giảng"}]);
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.encrypt_process) {
          if (val.encrypt_process.status !== ENCRYPT_MEDIA_PROCESS.STATUS_ERROR 
            && val.encrypt_process.status !== ENCRYPT_MEDIA_PROCESS.STATUS_COMPLETE
            && val.encrypt_process.status !== ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO) {
            this.overlay = true;
          } else {
            this.overlay = false;
          }
          this.videoStatus = this.getVideoStatus(val.encrypt_process.status);
          this.classTextStatus = this.getClassTextStatus(val.encrypt_process.status);
        }
        else {
          this.videoStatus = '';
        }

        if(val && val.medias && val.medias.length > 0 && val.medias[0].src) {
          this.lessonMedia = LESSON_MEDIA_TYPE.VIDEO;
        } else if (val && val.medias && val.medias.length > 0 && !val.medias[0].src) {
          this.lessonMedia = LESSON_MEDIA_TYPE.TEXT_AND_IMAGE;
        }

        // this.reloadVideoView();
      }
    }
  },
  async mounted() {
    this.course_slug = this.$route.query.course_slug;
    this.lesson_slug = this.$route.query.lesson_slug;
    await this.$store.dispatch(GET_LESSON_DETAIL, {lesson_slug: this.lesson_slug});
    await this.$store.dispatch(GET_COURSE_DETAIL, {course_slug: this.course_slug});

  },
  methods: {
    comeBack() {
      this.$router.push({
        path: "/course",
        query: {course_slug: this.course_slug, viewType: 2},
      });
    },
    getContent(value) {
      this.lesson.content = value;
    },
    async onChangeFileUpload() {
      if (this.video) {
        this.overlay = true;
        this.uploading = true;
        let formData = new FormData();
        this.videoStatus = '';
        formData.append("file", this.video);
        await this.$store
          .dispatch(UPLOAD_LESSON_VIDEO, formData)
          .then((data) => {
            if (data.data.status == 1) {
              this.overlay = false;
              this.uploading = false;
              this.videoUrl = data.data.data;
              this.lesson.medias[0].src = this.videoUrl;
              this.reloadVideoView();
              this.setUploadedVideoStatus();
            }
        })
        .catch((err) => {
          console.log("err", err);
        });
      } else {
        this.videoUrl = null;
        this.lesson.medias[0].src = null;
        this.uploading = false;
        this.overlay = false;
        this.videoStatus = '';
      }
    },
    setUploadedVideoStatus() {
      this.lesson.encrypt_process.status = ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO;
    },
    reloadVideoView() {
      setTimeout(function() { 
        let el = document.getElementById("video");
        if(el) {
          el.load();
        }
      }, 2000);
    },
    handleUpload() {
      // format course data
      this.$store
          .dispatch(UPDATE_LESSON, {
            lesson: this.lesson,
          })
          .then(() => {
            this.$router.push({
              path: "/course",
              query: {course_slug: this.course.slug, viewType: 2},
            });
          });
    },
    getVideoStatus(status) {
      let videoStatus = '';
      switch(status) {
        case ENCRYPT_MEDIA_PROCESS.STATUS_ERROR: 
          videoStatus = 'MÃ HÓA VIDEO LỖI'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_START: 
          videoStatus = 'BẮT ĐẦU MÃ HÓA VIDEO'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_REMOVE_OLD_FILE: 
          videoStatus = 'ĐANG XÓA VIDEO CŨ TRÊN S3'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_ENCRYPTING: 
          videoStatus = 'ĐANG MÃ HÓA VIDEO'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_PUSH_TO_STORAGE: 
          videoStatus = 'ĐANG UPLOAD VIDEO TỚI S3'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_COMPLETE: 
          videoStatus = 'MÃ HÓA VIDEO HOÀN THÀNH'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO: 
          videoStatus = 'ĐÃ UPLOAD VIDEO'
          break;
        default:
          break;
      }
      return videoStatus;
    },
    getClassTextStatus(status) {
      let textClass = '';
      switch(status) {
        case ENCRYPT_MEDIA_PROCESS.STATUS_ERROR: 
          textClass = 'red--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_START: 
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_REMOVE_OLD_FILE: 
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_ENCRYPTING: 
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_PUSH_TO_STORAGE: 
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_COMPLETE: 
          textClass = 'green--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO: 
          textClass = 'blue--text'
          break;
        default:
          break;
      }
      return textClass;
    },
    getStartTimeEncrypt() {
      if (this.lesson && this.lesson.encrypt_process && this.lesson.encrypt_process.start) {
        return this.lesson.encrypt_process.start;
      }
      return '------------------------'
    },
    getEndTimeEncrypt() {
      if (this.lesson && this.lesson.encrypt_process && this.lesson.encrypt_process.end) {
        return this.lesson.encrypt_process.end;
      }
      return '------------------------'
    },
  },
};
</script>

<style>
/* #preview img {
  max-width: 100%;
  max-height: 500px;
} */
.ck-editor__editable {
  min-height: 150px;
}

.v-row {
  margin-bottom: 15px;
  padding-left: 10px;
}

</style>
